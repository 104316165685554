import Icon from "@/components/Icon";

export default function IconBox({ icon, color, size }) {
  // FORCE INCLUDE
  // "bg-brand-purple/10 bg-brand-purple/20 text-brand-purple"
  // "bg-brand-blue/10 bg-brand-blue/20 text-brand-blue"
  // "bg-brand-green/10 bg-brand-green/20 text-brand-green"
  // "bg-brand-orange/10 bg-brand-orange/20 text-brand-orange"
  // "bg-brand-yellow/10 bg-brand-yellow/20 text-brand-yellow"
  // "bg-brand-pink/10 bg-brand-pink/20 text-brand-pink"
  // "bg-brand-magenta/10 bg-brand-magenta/20 text-brand-magenta"
  // "bg-brand-red/10 bg-brand-red/20 text-brand-red"
  // "bg-brand-emerald/10 bg-brand-emerald/20 text-brand-emerald"
  // "bg-brand-royal/10 bg-brand-royal/20 text-brand-royal"
  // "bg-brand-lemon/10 bg-brand-lemon/20 text-brand-lemon"
  // "bg-brand-error/10 bg-brand-error/20 text-brand-error"
  // "bg-brand-success/10 bg-brand-success/20 text-brand-success"
  // "bg-brand-info/10 bg-brand-info/20 text-brand-info"
  // "bg-brand-warning/10 bg-brand-warning/20 text-brand-warning"
  // "xs:p-0.5 sm:p-0.5 md:p-0.5 lg:p-0.5 xl:p-0.5 2xl:p-0.5"
  // "xs:p-1 sm:p-1 md:p-1 lg:p-1 xl:p-1 2xl:p-1"
  // "xs:p-2 sm:p-2 md:p-2 lg:p-2 xl:p-2 2xl:p-2"
  // "xs:rounded-sm sm:rounded-sm md:rounded-sm lg:rounded-sm xl:rounded-sm 2xl:rounded-sm"
  // "xs:rounded-md sm:rounded-md md:rounded-md lg:rounded-md xl:rounded-md 2xl:rounded-md"
  // "xs:rounded-lg sm:rounded-lg md:rounded-lg lg:rounded-lg xl:rounded-lg 2xl:rounded-lg"
  // "xs:w-4 sm:w-4 md:w-4 lg:w-4 xl:w-4 2xl:w-4"
  // "xs:w-5 sm:w-5 md:w-5 lg:w-5 xl:w-5 2xl:w-5"
  // "xs:w-6 sm:w-6 md:w-6 lg:w-6 xl:w-6 2xl:w-6"
  // "xs:h-4 sm:h-4 md:h-4 lg:h-4 xl:h-4 2xl:h-4"
  // "xs:h-5 sm:h-5 md:h-5 lg:h-5 xl:h-5 2xl:h-5"
  // "xs:h-6 sm:h-6 md:h-6 lg:h-6 xl:h-6 2xl:h-6"

  const split = size.split(" ");
  let boxClasses = "flex items-center justify-center ";
  const boxColor = `bg-brand-${color}/20 `;
  const iconColor = `text-brand-${color} `;
  boxClasses += boxColor;

  let iconClasses = "";
  iconClasses += iconColor;

  split.forEach((part) => {
    let [screen, sz] = part.split(":");
    if (!sz) {
      sz = screen;
      screen = "";
    } else {
      screen = `${screen}:`;
    }

    let padding = sz === "xl" ? "p-2 " : "p-1 ";
    if (sz === "xs") padding = "p-0.5 ";
    boxClasses += `${screen}${padding}`;
    let rounded = sz === "xl" ? "rounded-lg " : "rounded-md ";
    if (sz === "xs") rounded = "rounded-sm ";
    boxClasses += `${screen}${rounded}`;

    let width = "w-5 ";
    let height = "h-5 ";
    if (sz === "xs") {
      width = "w-4 ";
      height = "h-4 ";
    }
    if (sz === "sm") {
      width = "w-4 ";
      height = "h-4 ";
    }
    if (sz === "lg") {
      width = "w-6 ";
      height = "h-6 ";
    }
    iconClasses += `${screen}${width}`;
    iconClasses += `${screen}${height}`;
  });

  return (
    <div className={boxClasses}>
      <Icon icon={icon} className={iconClasses} />
    </div>
  );
}
