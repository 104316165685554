import(/* webpackMode: "eager" */ "/app/node_modules/@headlessui/react/dist/components/popover/popover.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthKitProvider"] */ "/app/node_modules/@workos-inc/authkit-nextjs/dist/esm/authkit-provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["MinMaxButton"] */ "/app/node_modules/@workos-inc/authkit-nextjs/dist/esm/min-max-button.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Navigation.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/NavigationAccount.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/NavigationLogo.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/OrgMenu.js");
