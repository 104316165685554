"use client";

import { useState, useEffect, useRef } from "react";
import Icon from "@/components/Icon";
import CodeBlock from "@/components/CodeBlock";
import Button from "@/components/Button";

const heroHosting = `await fetch(\`https://your.domain/\${file.name}\`, {
  method: 'PUT',
  headers: {
    'Authorization': \`Bearer \${ITTYBIT_KEY}\`
  },
  body: file
});`;

const heroIntelligence = `const task = {
  url: 'https://your.domain/upload.mov',
  kind: 'summary',
}

const response = await fetch('https://api.ittybit.com/tasks', {
  method: 'POST',
  headers: {
    'Authorization': \`Bearer \${ITTYBIT_KEY}\`
  },
  body: JSON.stringify(task)
});`;

const heroSources = `const task = {
  url: 'https://your.domain/upload.mov',
  format: 'mp4',
  width: 1280,
  height: 720,
  layers: [
    {
      url: 'https://your.domain/logo.png',
      height: 40,
      right: 20,
    },
  ],
}`;

const heroTracks = `const tasks = [
  {
    url: 'https://your.domain/upload.mov',
    kind: 'subtitles',
  },
  {
    url: 'https://your.domain/upload.mov',
    kind: 'chapters',
  },
  {
    url: 'https://your.domain/upload.mov',
    kind: 'thumbnails',
  }
]`;

const heroAutomation = `fetch('https://api.ittybit.com/automations', {
  method: 'POST',
  headers: {
    'Authorization': \`Bearer \${ITTYBIT_KEY}\`
  },
  body: JSON.stringify({
    tasks: [
      { 
        kind: "summary" 
      },
      { 
        kind: "speech",
        speakers: true,
        next: [
          {
            kind: "subtitles",
          }
        ]
      },
      {
        format: "mp4",
        width: 1280,
        height: 720,
        layers: [
          {
            url: 'https://your.domain/logo.png',
            height: 40,
            right: 20,
          },
        ],
      }
    ]
  })
});`;

const heroTabs = [
  {
    id: 0,
    label: "Hosting",
    icon: "upload",
    code: heroHosting,
    image:
      "https://ittybit-app.ittybitcdn.com/med_4adcuE17Qo3KVbqW/hosting-hero.png",
    alt: "",
    bg: "bg-brand-blue/20",
    hover: "bg-brand-blue/20",
    text: "text-brand-blue",
    progress: "bg-brand-blue/100",
    color: "blue",
  },
  {
    id: 1,
    label: "Intelligence",
    icon: "intelligence",
    code: heroIntelligence,
    image:
      "https://ittybit-app.ittybitcdn.com/med_4adcuE15kdYRKXAp/Intelligence-hero.png",
    alt: "",
    bg: "bg-brand-magenta/20",
    hover: "bg-brand-magenta/20",
    text: "text-brand-magenta",
    progress: "bg-brand-magenta/100",
    color: "magenta",
  },
  {
    id: 2,
    label: "Sources",
    icon: "sources",
    code: heroSources,
    image:
      "https://ittybit-app.ittybitcdn.com/med_4adcuE16Nc9utW17/sources-hero.png",
    alt: "",
    bg: "bg-brand-green/20",
    hover: "bg-brand-green/20",
    text: "text-brand-green",
    progress: "bg-brand-green/100",
    color: "green",
  },
  {
    id: 3,
    label: "Tracks",
    icon: "tracks",
    code: heroTracks,
    image:
      "https://ittybit-app.ittybitcdn.com/med_4adcuE17xgLulhAC/tracks-hero.png",
    alt: "Video player with subtitles, chapters, and thumbnails",
    bg: "bg-brand-orange/20",
    hover: "bg-brand-orange/20",
    text: "text-brand-orange",
    progress: "bg-brand-orange/100",
    color: "orange",
  },
  {
    id: 4,
    label: "Automation",
    icon: "automation",
    code: heroAutomation,
    image:
      "https://ittybit-app.ittybitcdn.com/med_4ade7V91FQ8wOE2G/workflow-hero.png",
    alt: "Workflow diagram with several automation tasks",
    bg: "bg-brand-yellow/20",
    hover: "bg-brand-yellow/20",
    text: "text-brand-yellow",
    progress: "bg-brand-yellow/100",
    color: "yellow",
  },
];

export const Tabs = ({
  tabs = [],
  activeTab = tabs[0],
  setActiveTab = () => {},
  delay = 3000,
  isPaused = false,
  setIsPaused = () => {},
}) => {
  const [progress, setProgress] = useState(0);
  const intervalRef = useRef(null);
  const progressIntervalRef = useRef(null);

  const inactiveTabColors = {
    bg: `bg-gray-500/20`,
    text: `text-gray-500`,
    progress: `bg-gray-500`,
  };

  // Set up the interval for automatic rotation
  useEffect(() => {
    // Function to rotate to the next tab
    const rotateTab = () => {
      const currentIndex = tabs.findIndex((tab) => tab.id === activeTab.id);
      const nextIndex = (currentIndex + 1) % tabs.length;
      setActiveTab(tabs[nextIndex]);
      setProgress(0);
    };

    if (!isPaused) {
      intervalRef.current = setInterval(rotateTab, delay);
      progressIntervalRef.current = setInterval(() => {
        setProgress((prev) => Math.min(prev + 1, 100));
      }, delay / 100);
    }
    return () => {
      clearInterval(intervalRef.current);
      clearInterval(progressIntervalRef.current);
    };
  }, [isPaused, activeTab, setActiveTab, tabs, delay]);

  // update tab on click
  const handleTabClick = (index) => {
    if (tabs[index].id === activeTab.id) {
      if (isPaused) {
        // Restart rotation if clicking the same tab
        setIsPaused(false);
      } else {
        setActiveTab(tabs[index]);
        setIsPaused(true);
      }
    } else {
      setActiveTab(tabs[index]);
      setIsPaused(true);
      setProgress(50);
    }
  };

  return (
    <ul className="flex items-start justify-center sm:gap-2 xl:gap-4 text-sm md:text-base leading-6 font-medium z-20">
      {tabs?.map((tab, index) => (
        <li key={tab.id} className="grow">
          <button
            onClick={() => handleTabClick(index)}
            className="group w-full"
          >
            <div className="flex w-full items-center justify-center gap-2 px-2 xl:px-4 pb-1 pt-2">
              <div
                className={`hidden md:block ${
                  tab.id === activeTab.id
                    ? activeTab.bg
                    : `${inactiveTabColors.bg} group-hover:${tab.hover}`
                } rounded-sm p-1 transition transition-colors transition-1s`}
              >
                <Icon
                  icon={tab.icon}
                  className={`w-5 h-5 ${
                    tab.id === activeTab.id
                      ? activeTab.text
                      : `${inactiveTabColors.text} group-hover:${tab.text}`
                  } transition transition-colors transition-1s`}
                />
              </div>
              <span>{tab.label}</span>
            </div>
            <div
              className={`w-full h-1 mt-1 ${
                tab.id === activeTab.id
                  ? activeTab.bg
                  : `${inactiveTabColors.bg} group-hover:${tab.hover}`
              } overflow-hidden rounded-full transition transition-colors transition-1s`}
            >
              <div
                className={`h-full ${
                  tab.id === activeTab.id
                    ? activeTab.progress
                    : `${inactiveTabColors.progress} group-hover:${tab.progress}`
                }`}
                style={{
                  width: tab.id === activeTab.id ? `${progress}%` : "0%",
                  transition: "width 60ms linear",
                }}
              />
            </div>
          </button>
        </li>
      ))}
      <button
        onClick={() => setIsPaused(!isPaused)}
        className={`md:${
          inactiveTabColors.bg
        } ${inactiveTabColors.text} md:rounded-sm md:p-1 mt-3 md:mt-2`}
      >
        <Icon
          icon={isPaused ? "loop" : "pause"}
          className={`w-4 h-4 md:w-5 md:h-5`}
        />
      </button>
    </ul>
  );
};

export const HeroTabs = ({ tabs = heroTabs }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const buttonColors = `transition transition-colors transition-1s bg-brand-${activeTab.color}/20 hover:bg-brand-${activeTab.color} hover:bg-opacity-100 text-brand-${activeTab.color} hover:text-white border border-brand-${activeTab.color} focus:border-brand-${activeTab.color}`;
  return (
    <div className="relative w-full flex flex-col items-center gap-4 lg:gap-6 xl:gap-8 px-8 lg:px-24 xl:px-12 3xl:px-0">
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isPaused={isPaused}
        setIsPaused={setIsPaused}
      />
      <div className="flex flex-col xl:flex-row items-stretch xl:items-start justify-stretch gap-4 md:gap-8 xl:gap-4">
        <div className="relative w-full grid grid-cols-1 grid-rows-1 z-20">
          <img
            src={activeTab.image}
            alt={activeTab.alt}
            className="relative col-start-1 col-end-1 row-start-1 row-end-1 w-full aspect-video object-contain blur-3xl opacity-50"
          />
          <img
            src={activeTab.image}
            alt={activeTab.alt}
            className="relative col-start-1 col-end-1 row-start-1 row-end-1 w-full aspect-video object-contain z-20"
          />
        </div>
        <div className="relative w-full flex-shrink-0 overflow-hidden rounded-lg bg-black shadow-[0_4px_64px_0px] shadow-brand-purple/20 xl:top-4 2xl:top-12 xl:w-[480px]">
          <div className="relative flex flex-col items-stretch justify-stretch bg-black w-full h-full overflow-hidden z-10">
            <div className="w-full h-full overflow-x-hidden overflow-y-auto py-2">
              <div className="flex flex-col items-stretch justify-stretch w-full h-full overflow-hidden">
                <div className="w-full h-[320px] xl:h-[360px] 2xl:h-[400px] overflow-x-hidden overflow-y-auto">
                  <CodeBlock
                    language={activeTab.language || "javascript"}
                    code={activeTab.code}
                    className="w-full overflow-auto text-xs md:text-sm leading-5 md:leading-6"
                    lineNumbers={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="https://ittybit-app.ittybitcdn.com/med_4acbbQ75z6NmgiM1/ittybot-cropped.webp"
          width="360"
          height="570"
          className="hidden xl:block w-[180px] 2xl:w-[216px] h-auto absolute -bottom-4 right-6 3xl:right-0 pointer-events-none z-10"
          alt=""
          style={{
            transform: "scaleX(-1)",
          }}
        />
      </div>
      <div className="w-full flex flex-col lg:flex-row items-center justify-center gap-4 lg:gap-6">
        <Button
          href="/register"
          custom
          label="Get API Key"
          size="xl"
          className={buttonColors}
        />
        <Button href="/docs" label="View Docs" size="lg" />
      </div>
    </div>
  );
};
