import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import Link from "@/components/Link";
import IconBox from "@/components/IconBox";

const docsItems = [
  {
    icon: "docs",
    label: "Documentation",
    href: "/docs",
    color: "purple",
    primary: true,
  },
  {
    icon: "terminal",
    label: "API Reference",
    href: "/docs/api",
    color: "purple",
    primary: true,
  },
  {
    icon: "education",
    label: "How-To Guides",
    href: "/guides",
    color: "purple",
    primary: true,
  },
  {
    icon: "workflows",
    label: "Changelog",
    href: "/docs/changelog",
    color: "purple",
    primary: true,
  },
  {
    icon: "help",
    label: "Help Center",
    href: "/support",
    color: "purple",
    primary: false,
  },
  {
    icon: "support",
    label: "FAQ",
    href: "/faq",
    color: "purple",
    primary: false,
  },
];

export default function DocsDropdown({ reverse = false }) {
  return (
    <Popover className="relative group">
      <PopoverButton>
        <Link
          href="/docs"
          className={`flex items-center gap-3 px-3 py-2 font-medium opacity-80 hover:opacity-100 flex-shrink-0 ${
            reverse ? "flex-row-reverse" : "flex-row"
          }`}
        >
          <div className="block group-hover:hidden group-focus-within:hidden">
            <IconBox icon="docs" color="purple" size="lg" />
          </div>
          <div className="hidden group-hover:block group-focus-within:block">
            <IconBox icon="expand" color="purple" size="lg" />
          </div>
          <span className="flex flex-col text-base text-right whitespace-nowrap">
            Docs
          </span>
        </Link>
      </PopoverButton>

      <PopoverPanel
        static
        className={`absolute z-10 mt-0 flex ${
          reverse ? "right-0 translate-x-8" : "left-0 translate-x-4"
        } px-4 transition translate-y-1 opacity-0 pointer-events-none group-hover:opacity-100 group-focus-within:opacity-100 group-hover:translate-y-0 group-focus-within:translate-y-0 group-hover:pointer-events-auto group-focus-within:pointer-events-auto`}
      >
        <div className="w-auto overflow-hidden rounded-xl bg-dark-400 ring-1 ring-brand-purple/5 lg:max-w-3xl">
          <div className="grid grid-cols-1 w-64 py-1">
            {docsItems
              .filter((item) => item.primary)
              .map((item) => (
                <div
                  key={item.label}
                  className="group relative flex flex-shrink-0 items-center gap-x-4 rounded-lg px-3 py-3 hover:bg-dark-500"
                >
                  <IconBox icon={item.icon} color={item.color} size="lg" />
                  <div>
                    <Link
                      href={item.href}
                      className="font-medium text-1 text-base leading-6"
                    >
                      {item.label}
                      <span className="absolute inset-0" />
                    </Link>
                  </div>
                </div>
              ))}
            {docsItems
              .filter((item) => !item.primary)
              .map((item) => (
                <div
                  key={item.label}
                  className="group relative flex flex-shrink-0 items-start gap-x-5 rounded-lg pl-4 pr-3 py-3 hover:bg-dark-500"
                >
                  <IconBox icon={item.icon} color={item.color} size="md" />
                  <div>
                    <Link
                      href={item.href}
                      className="font-medium text-2 text-sm leading-6"
                    >
                      {item.label}
                      <span className="absolute inset-0" />
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </PopoverPanel>
    </Popover>
  );
}
