"use client";

import Button from "@/components/Button";
import CallToAction from "@/components/CallToAction";
import Icon from "@/components/Icon";
import Link from "@/components/Link";
import Testimonial from "@/components/Testimonial";
import { initFadeInObserver } from "@/utils";
import { useEffect } from "react";
import { HeroTabs } from "./HeroTabs";
import Diff from "@/components/Diff";
import DocsItem from "@/components/DocsItem";
import BenefitBox from "@/components/BenefitBox";

// const upload = {
//   id: "upload",
//   section: "hosting",
//   icon: "upload",
//   href: "/guides/upload",
//   title: "Upload Files",
//   description: "Learn how to upload your media to Ittybit",
//   color: "blue",
// };
// const ingest = {
//   id: "ingest",
//   section: "hosting",
//   icon: "ingest",
//   href: "/guides/ingest",
//   title: "Ingest Media",
//   description: "Learn how to ingest your existing media to Ittybit",
//   color: "blue",
// };
// const deliver = {
//   id: "deliver",
//   section: "hosting",
//   icon: "cdn",
//   href: "/guides/deliver",
//   title: "Deliver Media",
//   description: "Learn how to deliver media to your users",
//   color: "blue",
// };
// const more_guides = {
//   id: "more_guides",
//   section: "guides",
//   href: "/guides?api=hosting",
//   dashboardItem: true,
//   menuItem: false,
//   title: "More Guides",
//   icon: "education",
//   color: "blue",
//   description: "View more hosting guides",
// };

const features = [
  {
    id: "hosting",
    label: "Easy uploads, secure storage, and rapid global delivery",
    url: "/apis/hosting",
    icon: "upload",
    image: "https://ittybit-app.ittybitcdn.com/med_4a5oal9933F5JkDh/ingest.png",
    alt: "",
    bullets: [
      "Add fast, reliable, uploads to your product in minutes",
      "Store all your app's video, image, and audio files securely",
      "Download or stream your media globally with automatic CDN",
      "Fully whitelabel with your own custom domain",
    ],
    bg: "bg-brand-blue/20",
    hover: "bg-brand-blue hover:bg-opacity-100",
    text: "text-brand-blue",
    border: "border-brand-blue",
    // items: [upload, ingest, deliver, more_guides],
  },
  {
    id: "intelligence",
    label: "Make your video, image, and audio files more useful",
    url: "/apis/intelligence",
    icon: "intelligence",
    image:
      "https://ittybit-app.ittybitcdn.com/med_49feca91RcW4Kxmi/describe.jpg",
    alt: "",
    bullets: [
      "Describe or summarize images, videos, and audio",
      "Make media searchable by extracting text, objects, and speech",
      "Detect and filter out NSFW uploads before your users see them",
      "Get continuous updates to the latest best-in-class models, without needing to change your code",
    ],
    bg: "bg-brand-magenta/20",
    hover: "bg-brand-magenta hover:bg-opacity-100",
    text: "text-brand-magenta",
    border: "border-brand-magenta",
  },
  {
    id: "sources",
    label: "Convert, compress, and transform your media files",
    url: "/apis/sources",
    icon: "sources",
    image:
      "https://ittybit-app.ittybitcdn.com/med_49iipk03b0U7AuPV/transforms-hero.png",
    alt: "",
    bullets: [
      "Convert files to modern formats for maximum compatibility",
      "Compress media files up to 90% without sacrificing quality",
      "Resize and crop content to specific aspect ratios",
      "Add watermarks and personalization to your media",
    ],
    bg: "bg-brand-green/20",
    hover: "bg-brand-green hover:bg-opacity-100",
    text: "text-brand-green",
    border: "border-brand-green",
  },
  {
    id: "tracks",
    label: "Make your media player engaging and accessible",
    url: "/apis/tracks",
    icon: "tracks",
    image:
      "https://ittybit-app.ittybitcdn.com/med_4a4aR955xQy5ehQg/thumbnails.png",
    alt: "",
    bullets: [
      "Add poster images to increase clickthrough and playback rates",
      "Improve SEO and accessibility with automatic subtitles and captions",
      "Help viewers navigate with interactive chapter markers",
      "Enable preview scrubbing with thumbnail timelines",
    ],
    bg: "bg-brand-orange/20",
    hover: "bg-brand-orange hover:bg-opacity-100",
    text: "text-brand-orange",
    border: "border-brand-orange",
  },
  {
    id: "automations",
    label: "Build robust media workflows in just a few lines of code",
    bullets: [
      "Combine hosting, intelligence, sources, and tracks APIs",
      // "Run tasks in sequence, in parallel, and based on file conditions",
      // "Trigger workflows on file upload, or on a schedule",
      "Trigger workflows via API or run automatically for every new file",
      "Access full logs, or add webhooks for instant notifications",
      "Scale effortlessly with our broadcast-grade infrastructure",
    ],
    url: "/apis/automations",
    icon: "automation",
    image: "https://ittybit-app.ittybitcdn.com/med_4aboem411wzCaIvl/runs.png",
    alt: "",
    bg: "bg-brand-yellow/20",
    hover: "bg-brand-yellow hover:bg-opacity-100",
    text: "text-brand-yellow",
    border: "border-brand-yellow",
  },
];

const benefits = [
  {
    id: "multimodal",
    icon: "media",
    title: "Multimodal",
    description:
      "Handle weird image formats, large videos, and audio inputs with a single consistent API",
  },
  {
    id: "built-for-devs",
    icon: "terminal",
    title: "Built for devs",
    description:
      "We sweat the small details that create a delightful developer experience",
  },
  {
    id: "scale-with-ease",
    icon: "stats",
    title: "Scale with ease",
    description:
      "Broadcast-scale systems let you handle millions of files without infra headaches",
  },
  {
    id: "don't-go-broke",
    icon: "pricing",
    title: "Don't go broke",
    description:
      "Costs significantly less than AWS, whilst being a lot faster (and more fun) to work with",
  },
];

export default function HomePageContent() {
  useEffect(() => {
    initFadeInObserver();
  }, []);

  return (
    <div className="home flex flex-col gap-24 2xl:gap-48 py-16 2xl:py-32">
      <section
        id="hero"
        className="fade-in w-full max-w-screen-2xl mx-auto relative"
      >
        <div className="max-w-screen-lg mx-auto px-4 md:px-8 lg:px-12 3xl:px-0">
          <div className="text-center space-y-2">
            <h1
              className="flex flex-row flex-wrap items-center justify-center gap-4 text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-bold font-title"
              style={{ textWrap: "balance" }}
            >
              <span>Media APIs for AI devs</span>
            </h1>
            <p className="text-lg md:text-xl lg:text-2xl xl:text-3xl text-2">
              Stop messing with S3 permissions and FFmpeg copypasta. Add
              scalable media handling to your apps in minutes.
            </p>
          </div>
        </div>
        <div className="fade-in w-full max-w-screen-2xl mx-auto relative mt-8 md:mt-12 xl:mt-16">
          <HeroTabs />
        </div>
      </section>

      <section id="benefits" className="fade-in">
        <div className="max-w-screen-2xl mx-auto px-4 md:px-8 lg:px-12 3xl:px-0 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 xl:gap-6">
          {benefits.map((benefit) => (
            <BenefitBox key={benefit.id} benefit={benefit} />
          ))}
        </div>
      </section>

      <section id="features" className="fade-in">
        <div className="text-center max-w-screen-lg mx-auto px-4 md:px-8 lg:px-12 3xl:px-0">
          <h2 className="text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-bold font-title">
            Ittybit makes media APIs you'll actually enjoy using
          </h2>
          <div className="mt-8 lg:mt-12 2xl:mt-16">
            <Diff
              size="lg"
              remove="Microservices, GPUs, concurrency, queues, timeouts, glue code"
              add="A single consistent API that handles scaling for you"
            />
          </div>
        </div>
      </section>

      {features.map((feature, index) => (
        <section key={feature.id} id={feature.id} className="fade-in">
          <div className="w-full max-w-screen-2xl mx-auto flex flex-col items-center px-4 md:px-8 lg:px-12 3xl:px-0">
            <Link
              href={feature.url}
              className="w-full opacity-90 hover:opacity-100 focus:opacity-100"
            >
              <div className="flex items-center justify-center gap-3 w-full">
                <div
                  className={`p-1 xl:p-1.5 ${feature.bg} rounded-sm xl:rounded-md`}
                >
                  <Icon
                    icon={feature.icon}
                    className={`w-6 h-6 xl:w-8 xl:h-8 ${feature.text}`}
                  />
                </div>
                <span
                  className={`font-pixel text-sm md:text-base xl:text-lg ${feature.text} uppercase`}
                >
                  {feature.id}
                </span>
              </div>
              <h3 className="font-title text-center text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-1 mt-1 md:mt-2">
                {feature.label}
              </h3>
            </Link>
          </div>
          <div
            className={`w-full max-w-screen-2xl mx-auto flex flex-col ${
              index % 2 === 0 ? "lg:flex-row-reverse" : "lg:flex-row"
            } lg:items-center gap-4 lg:gap-8 2xl:gap-32 px-4 md:px-8 lg:px-12 3xl:px-0 mt-6 md:mt-8 xl:mt-12`}
          >
            <div className="flex flex-col items-center lg:items-start">
              <div className="flex lg:hidden w-full justify-center px-8">
                <img
                  src={feature.image}
                  alt={feature.alt}
                  width="1280"
                  height="720"
                  className="rounded-lg w-full"
                />
              </div>
              <ul className="w-full text-sm md:text-base lg:text-lg xl:text-xl mt-6 lg:mt-0 pl-8">
                {feature.bullets?.map((bullet) => (
                  <li
                    key={bullet}
                    className={`relative list-none mb-1 md:mb-2 xl:mb-4 text-2`}
                  >
                    <Icon
                      icon="check"
                      className={`absolute top-1 -left-8 w-4 h-4 ${feature.text}`}
                    />
                    <span className="text-2">{bullet}</span>
                  </li>
                ))}
              </ul>
              <Button
                href={feature.url}
                custom
                label={`View ${feature.id} APIs`}
                className={`capitalize ${feature.bg} hover:${feature.hover} focus:${feature.hover} ${feature.text} hover:text-white border ${feature.border} focus:${feature.border} mt-4 md:mt-8`}
              />
            </div>

            <div className="hidden lg:grid grid-cols-1 grid-rows-1 sm:px-8 md:px-16 lg:px-0 lg:w-[50%] 2xl:w-[800px] lg:flex-shrink-0">
              <img
                src={feature.image}
                alt={feature.alt}
                className="col-start-1 row-start-1 w-full aspect-video overflow-hidden rounded-lg object-cover blur-3xl opacity-50"
              />
              <img
                src={feature.image}
                alt={feature.alt}
                className="col-start-1 row-start-1 w-full aspect-video overflow-hidden rounded-lg object-cover z-10"
              />
            </div>
          </div>
          {feature.items ? (
            <div className="w-full max-w-screen-2xl mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 md:gap-6 xl:gap-8 px-4 md:px-8 lg:px-12 3xl:px-0 mt-8 md:mt-12 xl:mt-16">
              {feature.items?.map((item) => (
                <DocsItem key={item.id} item={item} />
              ))}
            </div>
          ) : null}
        </section>
      ))}

      <section className="fade-in w-full max-w-screen-2xl mx-auto px-4 md:px-8 lg:px-12 3xl:px-0">
        <CallToAction color="purple" docs />
      </section>

      <section className="fade-in">
        <div className="max-w-screen-xl mx-auto px-4 md:px-8 lg:px-12 3xl:px-0">
          <Testimonial
            text="Our PE lessons are used by 80,000 kids around the globe. Ittybit helps us serve HD video reliably and affordably to any region, and that means kids can train wherever they live."
            name="Simon Brundish"
            title="Founder, Strength:Lab"
            avatar="https://ittybit-app.ittybitcdn.com/img/simon.jpg"
            logo="https://ittybit-app.ittybitcdn.com/img/strengthlab-logo-white@2x.png"
            image="https://ittybit-app.ittybitcdn.com/med_49im8p54YLdPzCC1/sl-image.png"
            color="purple"
          />
        </div>
      </section>
    </div>
  );
}
